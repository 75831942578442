pattern-test-header, pattern-learn-header, .interactive {
  display: none;
}

p {
  margin-top: 8px;
  margin-bottom: 8px;
}

/*# sourceMappingURL=index.07db4401.css.map */
